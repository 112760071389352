<template>
  <div>
    <div class="card">
      <div class="bg-blue-light px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="List"
            :showBtn="true"
            :showAddNew="false"
            :title="headerTitle"
            @onClickCloseButton="navigateToListPage"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Requester</label>
              <input type="text" class="form-control" :value="formData.user" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Requisition No</label>
              <input type="text" class="form-control" :value="formData.requisition_number" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Department</label>
              <v-select
                  placeholder="Select Department"
                  v-model="formData.department_id"
                  :options="departments"
                  label="name"
                  :reduce="name => name.id"
                  disabled
              />
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Requisition Date</label>
              <input type="date" class="form-control" v-model="formData.date" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Project</label>
              <v-select
                  placeholder="Select Project"
                  v-model="formData.project_id"
                  :options="projects"
                  label="name"
                  :reduce="name => name.id"
                  disabled
              />
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Business</label>
              <v-select
                  placeholder="Select Business"
                  v-model="formData.business_id"
                  :options="businesses"
                  label="name"
                  :reduce="name => name.id"
                  disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div class="px-2">
        <ul class="nav nav-tabs" role="tablist">
          <template v-if="isRequisitionCreate">
            <li class="nav-item">
              <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" disabled
                 class="nav-link"
                 data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
            </li>
            <li class="nav-item">
              <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" disabled
                 class="nav-link"
                 data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
            </li>
          </template>

          <template v-else>
            <li class="nav-item">
              <a :class="{'active' : formData.has_item_detail, 'disabled' : !formData.has_item_detail}" class="nav-link"
                 data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
            </li>
            <li class="nav-item">
              <a :class="{'active' : !formData.has_item_detail, 'disabled' : formData.has_item_detail}" class="nav-link"
                 data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
            </li>
          </template>
        </ul>
      </div>

      <div class="px-2 mt-2"
           v-show="!isItem"
      >
        <AddItemDetails
            class="mb-2"
            :products="products"
            :accountHeads="accountHeads"
            v-for="(item, index) in formData.account_details"
            :key="index"
            :index="index"
            :item="item"
            :isItem="isItem"
            :vatRate="vatRate"
            :isDisabled="true"
            @onClose=""
        />
      </div>
      <div class="px-2 mt-2"
           v-show="isItem"
      >
        <AddItemDetails
            class="mb-2"
            :products="products"
            :accountHeads="accountHeads"
            v-for="(item, index) in formData.item_details"
            :key="index"
            :index="index"
            :item="item"
            :isItem="isItem"
            :vatRate="vatRate"
            :isDisabled="true"
            @onClose=""
        />
      </div>

      <div class="p-1">
        <div class="row mt-3" v-if="formData.attachments.length > 0">
          <div class="col-12">
            <div class="mb-2">
              <label for="attachment" class="form-label fw-bold">Attachment</label>
              <BlobFileViewer :deletePermission="false" :attachments="formData.attachments"/>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2" id="description"
                      readonly></textarea>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-7"></div>
          <div class="col-5">
            <div class="row mt-1">
              <div class="col-md-4 align-middle">SubTotal :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="subTotal" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">VAT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="totalVAT" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">Total :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="total" readonly>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-12 mt-1">
          <div class="mb-1">
            <label class="form-label" for="note">Comments</label>
            <textarea
                v-model="comment"
                placeholder="Comments"
                class="form-control"
                rows="1"
                :readonly="viewOnly"
            />
          </div>
        </div>
      </div>
      <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2" >
        <hr class="hr-full-width mb-1">
        <slot v-if="!viewOnly" >
          <button :disabled="isDisable"
                  class="btn btn-danger"
                  @click="changeStatus('rejected')">
            Reject
          </button>

          <button
              :disabled="isDisable"
              class="btn btn-primary"
              @click="changeStatus('returned')"
          >
            Return
          </button>

          <button
              :disabled="isDisable"
              class="btn btn-success"
                @click="changeStatus((formData.status !== 'pending_approve') ? 'pending_approve' : 'approved')"
          >

              {{getConfirmButtonName()}}
          </button>
          <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
        </slot>
        <button @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)" class="btn btn-warning">Show More</button>
      </div>
    </div>

      <view-approval-matrix-details
          :workflowDetails="workflowDetails"
          :approvalLogs="formData.workflow_approval_logs ?? []"
          ref="viewApprovalMatrix"
      />

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {inject, ref, onMounted, computed, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/requisition/AddItemDetails"
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleProjects from "@/services/modules/procurement/project";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses';
import {vatRate} from '@/data/inventory.js'
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
const router = useRouter()
const route = useRoute()
const showError = inject('showError')
const showSuccess = inject('showSuccess')
const store = useStore()

const today = new Date().toISOString().split('T')[0];

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchProjects, fetchAccountsAndItemsByProjectId} = handleProjects();
const {fetchDepartmentList} = handleDepartments();
const {fetchBusinessList} = handleCBusinesses();
const {
  fetchRequisitionNumber,
  updateRequisitionStatus,
  fetchSingleRequisition,
} = handleRequisitions();
const {fetchWorkflowFromPageId} = handleWorkflow()

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let projects = ref([])
let businesses = ref([])
let products = ref([])
let accountHeads = ref([])
let departments = ref([])
let comment = ref('')
let formData = ref({
  requisition_number: null,
  department_id: null,
  date: null,
  project_id: null,
  project_budget_ref: null,
  business_id: null,
  subtotal_amount: 0.00,
  vat_amount: 0.00,
  total_amount: 0.00,
  has_item_detail: true,
  description: '',
  attachments: [],
  account_details: [
    {
      account_head_id: null,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null
    }
  ],
  item_details: [
    {
      product_id: null,
      quantity: null,
      rate: null,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null
    }
  ],
});

let backupFormData = ref({
  requisition_number: null,
  department_id: null,
  date: new Date().toISOString().split('T')[0],
  project_id: null,
  project_budget_ref: null,
  business_id: null,
  subtotal_amount: 0.00,
  vat_amount: 0.00,
  total_amount: 0.00,
  has_item_detail: true,
  description: '',
  attachments: [],
  account_details: [
    {
      account_head_id: null,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null
    }
  ],
  item_details: [
    {
      product_id: null,
      quantity: null,
      rate: null,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null
    }
  ],
});
let headerTitle   = ref('Requisition Approval')
let isRequisitionCreate = ref(true);
let requisitionEditData = ref({})
const viewOnly = ref(false);
const workflowDetails = ref({});
const approvalPageId = route.query.approval_page_id;
const viewApprovalMatrix = ref(null);

function navigateToListPage() {
  delete route.query.requisitionId;
  router.push({name: `requisition-approval`, params: route.params, query: route.query});
}

function changeStatus(status){
  if(status === 'approved' && ! confirm('Are you sure you want to approve this requisition')){
      return;
  }
  saveButtonLoader.value = true

  let statusData = {
    'status' : status,
    'workflow_master_id' : workflowDetails.value.id,
    'comment' : comment.value,
  }
  updateRequisitionStatus(route.query.requisitionId, getQuery(), statusData)
      .then(res => {
        saveButtonLoader.value = false
        if(res.status) {
          showSuccess(res.message)
          navigateToListPage();
          resetForm();
        }
        if(!res.status) {
          showError(res.message)
        }
      })
      .catch(err=> {
        saveButtonLoader.value = false;
        showError(err)
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  if (isRequisitionCreate.value) {
    formData.value.date     = new Date().toISOString().split('T')[0];
  } else {
    formData.value.requisition_number   = requisitionEditData.value.requisition_number;
    formData.value.user   = requisitionEditData.value.user.name;
    formData.value.date     = new Date(requisitionEditData.value.date).toISOString().split('T')[0];
    formData.value.description = requisitionEditData.value.description;
    formData.value.department_id = requisitionEditData.value.department_id;
    formData.value.project_id = requisitionEditData.value.project_id;
    formData.value.business_id = requisitionEditData.value.business_id;
    formData.value.has_item_detail = requisitionEditData.value.has_item_detail;
    formData.value.attachments = requisitionEditData.value.attachments;
    formData.value.approved_by_self = requisitionEditData.value.approved_by_self;
    formData.value.status = requisitionEditData.value.status;
    formData.value.account_details = requisitionEditData.value.has_item_detail == 0
                                     ? requisitionEditData.value.account
                                     : [{
                                          account_head_id: null,
                                          amount: null,
                                          vat: null,
                                          vat_amount: null,
                                          total_amount: null,
                                          expected_delivery_date: today,
                                          description: null
                                        }];
    formData.value.item_details = requisitionEditData.value.has_item_detail == 1
                                     ? requisitionEditData.value.general
                                     : [{
                                        product_id: null,
                                        quantity: null,
                                        rate: null,
                                        amount: null,
                                        vat: null,
                                        vat_amount: null,
                                        total_amount: null,
                                        expected_delivery_date: today,
                                        description: null
                                      }];
    formData.value.workflow_approval_logs = requisitionEditData.value.workflow_approval_logs;
  }
}

function resetForm(){
  formData.value = backupFormData.value;
}

async function getAccountsAndItemsByProjectId() {
  const res = await fetchAccountsAndItemsByProjectId(formData.value.project_id, getQuery());

  if (res.status) {
    accountHeads.value = res.data.accounts;
    products.value = res.data.items;
  }

  if (! res.status) {
    accountHeads.value = [];
    products.value = [];
  }
}

async function getDefaultAccountsAndItems() {
  const productRes = fetchProductList(getQuery());
  const accountHeadRes = fetchAccountHead(getQuery());

  await Promise.all([
    productRes.then(res=> {
      if(res.data) products.value = res.data
    }),
    accountHeadRes.then(res=> {
      if(res.data) accountHeads.value = res.data
    }),
  ]);
}

const getConfirmButtonName = () => {
    if(formData.value.approved_by_self > 0) {
        return 'Already Approved';
    }

    if(formData.value.status === 'pending_approve') {
        return 'Approve';
    }

    return "Confirm"
}

const isItem = computed(() => {
  return !!formData.value.has_item_detail
})

const subTotal = computed(() => {
    let subTotal=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            subTotal += parseInt(item.quantity * item.rate)
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if(item.amount) {subTotal += parseInt(item.amount)}
        })
    }
    
    return subTotal
})

const totalVAT = computed(() => {
    let vat=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            if(item.vat) {
                vat += parseInt((item.vat/100) * item.quantity * item.rate)
            }
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if(item.vat) {
                vat += parseInt((item.vat/100) * item.amount)
            }
        })
    }
    
    return vat.toFixed(2)
})

const total = computed(() => {
    return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

const projectId = computed(() => {
  return formData.value.project_id
})

const isDisable = computed(() => {
    return (saveButtonLoader.value ||
            saveNewButtonLoader.value ||
            (formData.value.approved_by_self > 0) ||
            (formData.value.status === 'returned')
    )
})

const getRequisition = () => {
  if (isRequisitionCreate.value){
    const requisitionNumberRes = fetchRequisitionNumber(getQuery());
    requisitionNumberRes.then(res => {
      formData.value.requisition_number = res;
      setFormData();
    })
  }else{
    const fetchRequisition = fetchSingleRequisition(route.query.requisitionId, getQuery());
    fetchRequisition.then(res => {
      requisitionEditData.value = res.data;
      setFormData();
    })
  }
}

onMounted(async () => {
  const projectsRes = fetchProjects(getQuery());
  const businessRes = fetchBusinessList(getQuery());
  const departmentListRes = fetchDepartmentList(getQuery());
  const workflowQuery = getQuery() + `&approval_page_id=${approvalPageId}`;
  if (route.name === 'requisition-view') {
    viewOnly.value = true;
  }

  if (route.query.requisitionId !== undefined && route.query.requisitionId !== null){
    isRequisitionCreate.value = false;
  }

  await Promise.all([
    projectsRes.then(res => {
      if(res.data) projects.value = res.data;
    }),
    businessRes.then(res => {
      if(res.data) businesses.value = res.data;
    }),
    departmentListRes.then(res=> {
       if(res.data) departments.value = res.data
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
       if(res.data) workflowDetails.value = res.data
    })
  ]);

  await getRequisition();
  await getDefaultAccountsAndItems();
})

watch(isItem, (newValue, oldValue) => {
    if (isRequisitionCreate.value){
      if(!newValue) {
        delete formData.value.item_details
        formData.value.account_details = [{
          account_head_id: null,
          amount: null,
          vat: 0,
          vat_amount: null,
          total_amount: null,
          expected_delivery_date: today,
          description: null
        }]
      }
      if(newValue) {
        delete formData.value.account_details
        formData.value.item_details = [{
          product_id: null,
          quantity: null,
          rate: null,
          amount: null,
          vat: 0,
          vat_amount: null,
          total_amount: null,
          expected_delivery_date: today,
          description: null
        }]
      }
    }
})

watch(projectId, (newValue, oldValue) => {
  if (newValue !== null){
    getAccountsAndItemsByProjectId();
  }else{
    getDefaultAccountsAndItems();
  }
})
</script>